<template>
  <div>
    <div
      v-if="isShowSettings"
      class="settings"
    >
      <div class="settings_main">
        <div class="setting_icon">
          <div class="icon_style">
            <a-button
              ghost
              :class="{'selected_btn': selectedBtnIndex === 0 }"
              @click="plus"
            >
              <a-icon type="zoom-in" />放大
            </a-button>
          </div>
          <div class="icon_style">
            <a-button
              ghost
              :class="{'selected_btn': selectedBtnIndex === 1 }"
              @click="minus"
            >
              <a-icon type="zoom-out" />缩小
            </a-button>
          </div>
          <div class="icon_style">
            <a-button
              ghost
              :class="{'selected_btn': selectedBtnIndex === 2 }"
              @click="reset"
            >
              <a-icon type="sync" />重置
            </a-button>
          </div>
          <div class="icon_style">
            <a-button
              ghost
              @click="exit"
            >
              <a-icon type="close-circle" />退出
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageSetting',
  props: {
    isShowSettings: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      zoomOut: 1,
      selectedBtnIndex: -1
    }
  },
  methods: {
    plus () {
      this.selectedBtnIndex = 0
      const app = document.getElementById('page_size')
      this.zoomOut = this.zoomOut + 0.02
      app.style.zoom = this.zoomOut
      this.$emit('changeZoom', this.zoomOut)
    },
    minus () {
      this.selectedBtnIndex = 1
      if (this.zoomOut > 1) {
        const app = document.getElementById('page_size')
        this.zoomOut = this.zoomOut - 0.02
        app.style.zoom = this.zoomOut
        this.$emit('changeZoom', this.zoomOut)
      }
    },
    reset () {
      this.selectedBtnIndex = 2
      const app = document.getElementById('page_size')
      this.zoomOut = 1
      app.style.zoom = 1
      this.$emit('changeZoom', this.zoomOut)
    },
    exit () {
      this.selectedBtnIndex = -1
      this.$emit('exit', this.isShowSettings)
    }
  }
}
</script>
<style lang="less" scoped>
.settings {
  min-width: 1200px;
  height: 50px;
  background-color: @theme-color;
}
.settings_main {
  width: 1200px;
  margin: auto;
}
.setting_icon {
  float: right;
  display: flex;
  height: 50px;
  align-items: center;
}
.icon_style:not(:last-of-type) {
  margin-right: 30px;
}
.icon_style:last-child {
  margin-right: 0px;
}
.icon_style img {
  height: 38px;
}
.icon_style {
  display: flex;
  button {
    padding: 0 15px;
    &:hover {
      background: #fff !important;
      color: @theme-color;
    }
  }
  button,
  button > .anticon {
    font-size: 16px;
  }
  .selected_btn {
    background: #fff !important;
    color: @theme-color;
  }
}
</style>
