<template>
  <div id="app">
    <PageSetting
      :isShowSettings="isShowSettings"
      @exit="exit"
      @changeZoom="changeZoom"
    />
    <router-view
      id="page_size"
      @handleSetting="handleSetting"
      :isShowSetting="isShowSettings"
      :zoomOut="zoomOut"
    />
  </div>
</template>

<script>
import PageSetting from './components/Setting'
export default {
  name: 'App',
  components: {
    PageSetting
  },
  data () {
    return {
      zoomOut: 1,
      isShowSettings: false,
    }
  },
  methods: {
    changeZoom (data) {
      this.zoomOut = data
    },
    exit () {
      this.isShowSettings = false
    },
    handleSetting (data) {
      this.isShowSettings = data
    },
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* 连续点击出现一片蓝色解决办法 */
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}
</style>
