const componentMap = {
  home: () => import('@/components/HomeIndex'),
  joinUs: () => import('@/components/JoinUs'),
  basicLayout: () => import('@/components/BasicLayout'),
  serviceMenu: () => import('@/components/ServiceMenu'),
  searchInfo: () => import('@/components/SearchResult'),
  notFound: () => import('@/components/NotFound'),
  searchDetails: () => import('@/components/SearchDetails'),
  joinDetail: () => import('@/components/JoinDetail'),
  articleDetails: () => import('@/components/ArticleDetails'),
  loginPage: () => import('@/components/LoginPage'),
  registerPage: () => import('@/components/RegisterPage'),
  changePasswordPage: () => import('@/components/ChangePasswordPage'),
  singlePage: () => import('@/components/SinglePage'),
  listPage: () => import('@/components/ListPage'),
  servicePage: () => import('@/components/ServicePage'),
  innerService: () => import('@/components/InnerService'),
  demandPage: () => import('@/components/DemandPage')
}
const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: { title: '首页' }
  },
  {
    path: '/home',
    name: 'index',
    component: componentMap.home,
    meta: { title: '首页' }
  },
  {
    path: '/login',
    name: 'login',
    component: componentMap.loginPage,
    meta: { title: '登录' }
  },
  {
    path: '/register',
    name: 'register',
    component: componentMap.registerPage,
    meta: { title: '注册' }
  },
  {
    path: '/changePasswordPage',
    name: 'changePasswordPage',
    component: componentMap.changePasswordPage,
    meta: { title: '修改密码' }
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: componentMap.joinUs,
    meta: { title: '入会' }
  },
  {
    path: '/joinDetail',
    name: 'joinDetail',
    component: componentMap.joinDetail,
    meta: { title: '入会标准' }
  },
  {
    path: '/basicLayout',
    name: 'basicLayout',
    component: componentMap.basicLayout,
    meta: { title: '基础页面' }
  },
  {
    path: '/serviceMenu',
    name: 'serviceMenu',
    component: componentMap.serviceMenu,
    meta: { title: '会员供需服务页面' }
  },
  {
    path: '/singlePage',
    name: 'singlePage',
    component: componentMap.singlePage,
    meta: { title: '（会员供需服务）合作机构模板' }
  },
  {
    path: '/listPage',
    name: 'listPage',
    component: componentMap.listPage,
    meta: { title: '（会员供需服务）会员风采模板' }
  },
  {
    path: '/servicePage',
    name: 'servicePage',
    component: componentMap.servicePage,
    meta: { title: '（会员供需服务）专业服务模板' }
  },
  {
    path: '/innerService',
    name: 'innerService',
    component: componentMap.innerService,
    meta: { title: '（会员供需服务）服务内部列表' }
  },
  {
    path: '/demandPage',
    name: 'demandPage',
    component: componentMap.demandPage,
    meta: { title: '（会员供需服务）会员需求' }
  },
  {
    path: '/articleDetails',
    name: 'articleDetails',
    component: componentMap.articleDetails,
    meta: { title: '文章详情' }
  },
  {
    path: '/searchInfo',
    name: 'searchInfo',
    component: componentMap.searchInfo,
    meta: { title: '搜索结果' }
  },
  {
    path: '/searchDetails',
    name: 'searchDetails',
    component: componentMap.searchDetails,
    mata: { title: '文章详情' }
  },
  {
    path: '*',
    name: 'notFound',
    component: componentMap.notFound
  }
]

export { routes }